<template>
  <div class="confirm-bind-modal">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <div class="mb-m">
        <h2 class="text-bold text-size-h2">Подтвердите действие</h2>
      </div>
      <div>
        <p class="mb-xs">
          Вы собираетесь привязать аккаунт Яндекс&nbsp;ID
          <a
            href="https://id.yandex.ru/"
            target="_blank"
            rel="noopener noreferrer"
            class="link"
            >{{ account.email || account.uid }}</a
          >
          к профилю участника НТО. После подтверждения привязать другой аккаунт
          будет нельзя.
        </p>
        <p class="mb-l">Привязать этот аккаунт?</p>
        <BaseButton
          :disabled="pending"
          @click="handleAccept"
          >Да, привязать</BaseButton
        >&emsp;
        <BaseButton
          theme="primary-border"
          @click="$emit('close')"
          >Нет, выбрать другой</BaseButton
        >
        <div
          v-if="error"
          class="form-error mb-s">
          {{ error }}
        </div>
      </div>
    </BaseModalContent>
  </div>
</template>
<script>
import { request } from "@/services/api";
export default {
  name: "ConfirmBindModal",
  props: {
    account: {
      type: Object,
    },
  },
  data() {
    return {
      error: "",
      pending: false,
    };
  },
  computed: {
    isParticipant() {
      return this.$store.getters["user/isParticipant"];
    },
  },
  methods: {
    async handleAccept() {
      if (this.pending) return;
      this.error = "";
      this.pending = true;
      try {
        await request({
          method: "POST",
          baseURL: "/yacontest",
          url: "/bind-yandex-id",
          data: {
            yid: Number(this.account.uid),
          },
        });
        if (this.isParticipant) {
          const userParticipant = {
            ...this.$store.state.user.user.participant,
            yandex_id: +this.account.uid,
          };
          this.$store.commit("user/PATCH_USER", {
            participant: userParticipant,
          });
        } else {
          this.$store.commit("user/PATCH_USER", {
            yandex_id: +this.account.uid,
          });
        }
        window.dataLayer?.push({
          event: "ntoProfileEvent",
          eventCategory: "nto_profile",
          eventAction: "oauth-add-yandex",
        });

        this.pending = false;
        this.$emit("close");
      } catch (error) {
        this.pending = false;
        this.error = error.message;
      }
    },
  },
};
</script>
