<template>
  <div>
    <BaseModalContent
      name="yandex-modal"
      :dynamic="true"
      @close="$emit('close')">
      <BindYandex @done="$emit('close')" />
    </BaseModalContent>
  </div>
</template>

<script>
import BindYandex from "@/components/BindYandex";
export default {
  name: "YandexModal",
  components: {
    BindYandex,
  },
};
</script>

<style></style>
