<template>
  <component
    :is="tag"
    v-if="activity && activity.id"
    :disabled="disabled"
    :block="block"
    :theme="theme"
    :loading="pending"
    @click.prevent="handleClick"
    ><slot>Перейти к заданию</slot></component
  >
</template>

<script>
import YandexModal from "@/modals/YandexModal";
import { clientApiRequest } from "@/services/api";
/**
 * Варианты ошибок при энроле на контест
 * @see https://stage.ntcontest.ru/docs/yacontest/#tag/client/operation/ContestParticipate
 */
const enrollErrors = {
  403: "У вас отсутствует загруженное подтверждение от родителей. Пожалуйста, загрузите файл согласия в личном кабинете платформы Талант.",
  404: "Не удалось найти соревнование. Пожалуйста, сообщите нам об этой ошибке.",
  409: "Платформа Яндекс.Контест вернула 409 ошибку. Пожалуйста, сообщите нам об этой ошибке.",
  422: "Регистрация на контест в данный момент отключена или не доступна согласно расписанию.",
};
export default {
  name: "EnrollButton",
  props: {
    activity: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    tag: {
      type: String,
      default: "BaseButton",
    },
    theme: {
      type: String,
      default: "primary",
    },
    // eslint-disable-next-line vue/no-unused-properties
    isEnrolled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
    },
    attemptId: {
      type: Number,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    requireYandex() {
      return this.$store.getters["user/requireYandex"];
    },
    isYandex() {
      return !!this.activity?.yacontest;
    },
    url() {
      const { activity } = this;
      if (!activity) return;
      return activity.external_url;
    },
  },
  methods: {
    openURL(url) {
      if (url && typeof url === "string") {
        window.location.href = url;
      } else {
        this.showErrorModal({
          content: "Не удалось перейти на курс",
          message: "Некорректное значение URL",
          url: url,
        });
      }
    },
    handleClick() {
      const { url, isYandex, disabled, pending } = this;
      if ((!isYandex && !url) || disabled || pending) return;
      // Если есть баллы или это внешняя активность,
      // то сразу кидаем на активность
      if (!isYandex) {
        this.openURL(url);
        return;
      }
      // Если активность на степике,
      // но у пользователя не привязан степик аккаунт
      if (this.requireYandex) {
        this.$modal.show(
          YandexModal,
          {},
          {
            adaptive: true,
            height: "auto",
          },
          {
            closed: this.onYandexModalClose,
          }
        );
        return;
      }
      this.enrollRequest();
    },
    async enrollRequest() {
      const { attemptId } = this;
      const yandexWindow = window.open(
        `${window.location.origin}/external-redirect`,
        "_blank"
      );
      try {
        this.pending = true;

        const { data: contestUrl } = await clientApiRequest({
          method: "POST",
          baseURL: "/yacontest",
          url: `/attempt/${attemptId}/participate`,
        });
        yandexWindow.location.href = contestUrl;
        this.pending = false;
      } catch (error) {
        // игнорируем ошибку о том,
        // что пользователь уже зарегистрирован на курс
        this.pending = false;
        yandexWindow.close();
        const status = error?.response?.status;
        console.log("%O", error?.config?.url, error.status);
        const msg = enrollErrors[status] || error.message;
        this.showErrorModal({
          content: "Не удалось перейти к соревнованию",
          message: msg,
          status,
          url: error?.config?.url,
          report: true,
        });
      }
    },
    onYandexModalClose() {
      if (!this.requireYandex) {
        this.handleClick();
      }
    },
  },
};
</script>

<style></style>
